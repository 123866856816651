import i18n from '@i18n';
import routes from '@routes';
import { downloadByUrl } from '@shared/utils/File';
import { notification } from '@components/ui';
import { store } from '@store';
import { viewerActions } from '@modules/viewer/duck/viewerSlice';
import { NO_WRAP_SPACE } from '@shared/GlobalConstants';
import { DownloadOutlined, QuestionCircleOutlined, ExportOutlined } from '@ant-design/icons';
import { createElement } from 'react';
import {
  CDRExtraTypes,
  TAction,
  TCDREvent,
  TDatastageEvent,
  TEvent,
  TMessage,
  TModelEventDetails,
  TRawEvent,
  TStoreEventDetails,
} from './messagesTypes';
const libraryRoutes = require('@routes/library/RoutesLibrary').default;

const MAX_LENGTH = 100;

export const isError = (event: TDatastageEvent): boolean =>
  (('error' in event.data && !!event.data.error) ||
    event.comment?.toLowerCase().includes('error') ||
    event.message?.toLowerCase().includes('error')) ??
  false;

const composeStoreMessage = (details: TStoreEventDetails, fallbackMessage?: TMessage): TMessage => {
  switch (details.action) {
    case 'data-store:file-processed':
      return { error: false, text: i18n.t('notification.store.fileProcessed', { name: details.data.name }) };
    case 'data-store:file-errored':
      return { error: true, text: i18n.t('notification.store.fileFailed', { name: details.data.name }) };
    case 'data-store:table-deleted':
      return { error: false, text: i18n.t('notification.store.tableDeleted', { name: details.data.name }) };
    default:
      return fallbackMessage || { error: false, text: i18n.t('notification.store.unknownAction', { id: details.id }) };
  }
};

const composeModelMessage = (details: TModelEventDetails, fallbackMessage?: TMessage): TMessage => {
  switch (details.action) {
    case 'data-model:run-finished':
      return { error: false, text: i18n.t('notification.model.finished', { name: details.data.name }) };
    default:
      return (
        fallbackMessage || {
          error: false,
          text: i18n.t('notification.model.unknownAction', { name: details.data.name }),
        }
      );
  }
};

const composeExistingMessage = (event: TEvent): TMessage => {
  if ('event' in event) {
    const _event = event as TDatastageEvent;
    return { error: isError(_event), text: _event.message ?? _event.comment };
  }
  return { error: false, text: event.comment };
};

const composeMessage = (extra: TRawEvent): TMessage => {
  const event = extra as TEvent;
  const existingMessage = event.comment || 'message' in event ? composeExistingMessage(event) : undefined;

  if ('event' in event) {
    const _event = event as TDatastageEvent;
    switch (_event.event) {
      case 'datastage:data-store-event':
        return composeStoreMessage(_event.data, existingMessage);
      case 'datastage:data-model-event':
        return composeModelMessage(_event.data, existingMessage);
      default:
        return existingMessage || { error: false, text: i18n.t('notification.unknownEvent') };
    }
  } else {
    const _event = event as TCDREvent;
    if (_event.type === CDRExtraTypes.ERROR_ALERT) {
      const errorText = `${_event.error_text}`.trim();
      return {
        error: true,
        text: i18n.t('notification.dna.unexpectedError', {
          error: errorText.length > MAX_LENGTH ? `${errorText.substring(0, MAX_LENGTH)}...` : errorText,
        }),
      };
    }
    if (_event.link) {
      return {
        error: false,
        text: i18n.t('notification.dna.link', {
          reportName: _event.reportName,
          studyName: _event.studyName,
          comment: _event.comment,
        }),
      };
    }
    if (_event.report_export_id) {
      return {
        error: false,
        text: i18n.t('notification.dna.report', {
          reportName: `"${_event.report_name}"` ?? '',
        }),
      };
    }
    if (_event.query_id) {
      return { error: false, text: i18n.t('notification.dna.query', { id: _event.query_id }) };
    }
    if (_event.source === 'schedulers') {
      if (_event.text && !_event.download_urls) {
        return { error: false, text: _event.text };
      }
      if (_event.description) {
        const description = _event.description;
        const reportName = description.includes('ready.')
          ? description.substring(0, description.indexOf('ready.') + 6)
          : 'Report';

        return { error: false, text: reportName };
      }
    }
    if (_event.reportName && _event.studyName) {
      const amt = _event.shared_rows_number;
      const rowWord = amt > 1 ? 'rows' : 'row';
      const rowsWithAmt = amt ? `(${amt}${NO_WRAP_SPACE}${rowWord})` : '';
      const comment = _event.comment ? `: ${_event.comment}` : '';

      return {
        error: false,
        text: i18n.t('notification.dna.report', {
          reportName: _event.reportName,
          studyName: _event.studyName,
          rows: rowsWithAmt,
          comment,
        }),
      };
    }

    return { error: false, text: event.comment ?? i18n.t('notification.unknownEvent') };
  }
};

const composeLink = (extra: TRawEvent): string => {
  const event = extra as TEvent;
  if ('event' in event) {
    const _event = event as TDatastageEvent;
    switch (_event.event) {
      case 'datastage:deployment-event':
        return libraryRoutes?.deploymentPlans?.resolver({ studyId: _event.data.study_id });
      case 'datastage:data-store-event':
        if (_event.data.action === 'data-store:table-deleted') {
          return routes.study.dataViewer.resolver({ studyId: _event.data.study_id });
        }
        if (_event.data.id) {
          return routes.study.jobs.view.resolver({ studyId: _event.data.study_id, jobId: _event.data.id });
        }
        return '';
      case 'datastage:data-model-event':
        return routes.study.dataViewer.resolver({ studyId: _event.data.study_id, tableId: _event.data.data.name });
      case 'datastage:table-export-event':
        return routes.study.dataViewer.resolver({
          studyId: _event.data.study_id,
          tableId: _event.data.data.table_name,
        });
      case 'datastage:dataset-event':
        // Allow user to open study if it is the only study in the list
        if (Object.keys(_event.data.successful ?? {}).length === 1) {
          const studyId = Object.keys(_event.data.successful)[0];
          if (_event.data.action === 'dataset:deleted') {
            return routes.study.dataset.resolver({ studyId });
          } else {
            return routes.study.dataViewer.resolver({
              studyId,
              tableId: _event.data.name,
            });
          }
        }
        return '';
      default:
        return '';
    }
  } else {
    return '';
  }
};

export const composeActions = (extra: TRawEvent): TAction[] | undefined => {
  const event = extra as TEvent;
  if ('event' in event) {
    const _event = event as TDatastageEvent;
    switch (_event.event) {
      case 'datastage:table-export-event':
        if (_event.data.action === 'file-export:finished') {
          return [
            {
              title: i18n.t('notification.export.download'),
              icon: createElement(DownloadOutlined),
              onClick: () => {
                store.dispatch(
                  viewerActions.removeTask({ study_id: _event.data.study_id, table_id: _event.data.data.table_name }),
                );
                notification.info({
                  message: i18n.t('notification.export.downloadStarted', {
                    file: _event.data.data.file_name,
                    study: _event.data.study_name ?? '',
                  }),
                });
                downloadByUrl(`/api/download/${_event.data.file_path}`);
              },
            },
          ];
        }

        if (_event.data.action === 'file-export:errored') {
          return [
            {
              title: i18n.t('notification.export.showError'),
              icon: createElement(QuestionCircleOutlined),
              onClick: () => {
                store.dispatch(
                  viewerActions.removeTask({ study_id: _event.data.study_id, table_id: _event.data.data.table_name }),
                );
                notification.error({
                  message:
                    _event.message ??
                    _event.comment ??
                    i18n.t('notification.export.failed', {
                      table: _event.data.data.table_name,
                      study: _event.data.study_name ?? '',
                    }),
                  description: _event.data.error,
                });
              },
            },
          ];
        }

        return undefined;
      case 'datastage:data-store-event':
        if (_event.data.action === 'data-store:file-errored') {
          return [
            {
              title: i18n.t('notification.store.showError'),
              icon: createElement(QuestionCircleOutlined),
              onClick: () => {
                notification.error({
                  message: i18n.t('notification.store.errorTitle'),
                  description: _event.data.error,
                });
              },
            },
          ];
        }

        return undefined;
      case 'datastage:dataset-event':
        const successful = Object.values(_event.data.successful ?? {});
        const failed = Object.values(_event.data.failed ?? {});

        if (successful.length > 3 || failed.length > 3 || (_event.data.error && _event.data.error.length > 50)) {
          const errorText = _event.data.error ? `Errors: ${_event.data.error}` : '';
          const description = `Success: ${successful.join(', ') || 'None'}. Failure: ${
            failed.join(', ') || 'None'
          }. ${errorText}`;
          return [
            {
              title: i18n.t('notification.showDetails'),
              icon: createElement(QuestionCircleOutlined),
              onClick: () => {
                const notification_method = isError(_event) ? notification.error : notification.info;
                notification_method({
                  message: i18n.t('notification.details'),
                  description,
                });
              },
            },
          ];
        }

        return undefined;
      default:
        return undefined;
    }
  } else {
    const _event = event as TCDREvent;
    if (_event.type === CDRExtraTypes.ERROR_ALERT) {
      const errorText = `${_event.error_text}`.trim();
      if (errorText.length <= MAX_LENGTH) {
        return undefined;
      }
      return [
        {
          title: i18n.t('notification.dna.showError'),
          icon: createElement(QuestionCircleOutlined),
          onClick: () => {
            notification.error({
              message: i18n.t('notification.dna.unexpectedError', { error: '' }),
              description: errorText,
            });
          },
        },
      ];
    }
    if (_event.link) {
      return [
        {
          title: i18n.t('notification.dna.linkText'),
          icon: createElement(ExportOutlined),
          onClick: () => window.location.replace(_event.link!),
        },
      ];
    }
    if (_event.report_export_id && _event.url) {
      return [
        {
          title: i18n.t('notification.dna.download'),
          icon: createElement(DownloadOutlined),
          onClick: () => window.location.replace(_event.url!),
        },
      ];
    }

    if (_event.source === 'schedulers' && _event.download_urls) {
      return _event.download_urls.map((url) => ({
        title: i18n.t('notification.dna.download'),
        icon: createElement(DownloadOutlined),
        onClick: () => {
          window.location.replace(url);
        },
      }));
    }

    return undefined;
  }
};

export const useMessage = () => ({ composeMessage, composeLink, composeActions });
